/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Obývací pokoje"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1pt247q css-11p91ce --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/05953693f118436090cc9edbe10df912_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/05953693f118436090cc9edbe10df912_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/05953693f118436090cc9edbe10df912_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/05953693f118436090cc9edbe10df912_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/05953693f118436090cc9edbe10df912_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/05953693f118436090cc9edbe10df912_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/05953693f118436090cc9edbe10df912_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/05953693f118436090cc9edbe10df912_s=3000x_.jpg);
    }
  
background-position: 50% 85%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Obývací pokoje</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"gldri53pug"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":678}}>
              
              <Title className="title-box" style={{"maxWidth":930}} content={"Místo, kde můžete ukázat svou jedinečnost, spojit estetiku a pohodlí."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":649}} content={"Vytvořme společně místo pro vaši relaxaci, pohodlí, práci i hraní. Inspirujte se a vyberte si svůj osobitý styl."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--80" name={"1d8u6246rn5"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/0073446015ef45e0993845af6df2cb76_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/0073446015ef45e0993845af6df2cb76_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/0073446015ef45e0993845af6df2cb76_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/0073446015ef45e0993845af6df2cb76_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/0073446015ef45e0993845af6df2cb76_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/020f9bd7343d42aea99e109250b66086_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/020f9bd7343d42aea99e109250b66086_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/020f9bd7343d42aea99e109250b66086_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/020f9bd7343d42aea99e109250b66086_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/020f9bd7343d42aea99e109250b66086_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10513/020f9bd7343d42aea99e109250b66086_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/9e9e685411e548699366cacf6cd90831_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/9e9e685411e548699366cacf6cd90831_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/9e9e685411e548699366cacf6cd90831_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/9e9e685411e548699366cacf6cd90831_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/9e9e685411e548699366cacf6cd90831_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/e26b6b25aed7404abd18a8071c267f85_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/e26b6b25aed7404abd18a8071c267f85_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/e26b6b25aed7404abd18a8071c267f85_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/e26b6b25aed7404abd18a8071c267f85_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/e26b6b25aed7404abd18a8071c267f85_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/8b76773e04f84b5f8008be3c61fa782d_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/8b76773e04f84b5f8008be3c61fa782d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/8b76773e04f84b5f8008be3c61fa782d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/8b76773e04f84b5f8008be3c61fa782d_s=860x_.jpg 860w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/168c2215ec6f4cf69398a1dc7de3e493_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/168c2215ec6f4cf69398a1dc7de3e493_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/168c2215ec6f4cf69398a1dc7de3e493_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/168c2215ec6f4cf69398a1dc7de3e493_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/168c2215ec6f4cf69398a1dc7de3e493_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/dfc47943911e4164ab60d5f03f9a15f4_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/dfc47943911e4164ab60d5f03f9a15f4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/dfc47943911e4164ab60d5f03f9a15f4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/dfc47943911e4164ab60d5f03f9a15f4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/dfc47943911e4164ab60d5f03f9a15f4_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/95c034aa5d834c91aad403c13a1720ec_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/95c034aa5d834c91aad403c13a1720ec_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/95c034aa5d834c91aad403c13a1720ec_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/95c034aa5d834c91aad403c13a1720ec_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/95c034aa5d834c91aad403c13a1720ec_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/f682f102b2504651b8d9f9b0bcb4cf95_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":923}} srcSet={"https://cdn.swbpg.com/t/10513/f682f102b2504651b8d9f9b0bcb4cf95_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/f682f102b2504651b8d9f9b0bcb4cf95_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/f682f102b2504651b8d9f9b0bcb4cf95_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/f682f102b2504651b8d9f9b0bcb4cf95_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10513/f682f102b2504651b8d9f9b0bcb4cf95_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-3yre0a css-42e4bw --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f53f90519754adc835cbfd5ac02bf21_r=810__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f53f90519754adc835cbfd5ac02bf21_r=810__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f53f90519754adc835cbfd5ac02bf21_r=810__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f53f90519754adc835cbfd5ac02bf21_r=810__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f53f90519754adc835cbfd5ac02bf21_r=810__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f53f90519754adc835cbfd5ac02bf21_r=810__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f53f90519754adc835cbfd5ac02bf21_r=810__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f53f90519754adc835cbfd5ac02bf21_r=810__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Máte zájem o obývací pokoj?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--center mt--16" use={"page"} href={"/kontakt"} content={"Kontaktovat&nbsp; ➔"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3 flex--center" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500" content={"<span style=\"color: var(--color-dominant);\">Living 4 Handy s.r.o.</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":224}} content={"<span style=\"color: rgb(220, 221, 221);\">Výrobce kvalitních kuchyní a nábytku na míru pro Vaši spokojenost. Realizujeme  po Praze, Středočeském kraji i okolí.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":473}} content={"<span style=\"color: rgb(248, 248, 248);\">IČ 25731491<br>DIČ CZ25731491<br><br>Společnost je vedená u Městského soudu v Praze, spisová značka C 65152</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500 lh--14" content={"<span style=\"color: var(--color-dominant);\">+420 777 206 285<br>info@living4handy.cz<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: rgb(220, 221, 221);\">Living4Handy s.r.o.\n<br>Luženská 2801<br>&nbsp;Rakovník</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}